
@import "@/styles/_variables.scss";

input[type="file"] {
  display: none;
}

.no-files {
  padding: 2.4rem 0.6rem;
  margin: 2.4rem 0;
  border-radius: $radius;
  font-size: 1.6rem;
  text-align: center;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.5);
}

.file-to-upload {
  padding: 0.6rem;
  padding-right: 1rem;
  margin-bottom: 1.2rem;
  border-radius: $radius;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.5);
  &:hover {
    background-color: rgba(0, 0, 0, 0.75);
  }
  &.error {
    background-color: rgba($danger, 0.75);
  }
  overflow: hidden;

  position: relative;
  display: flex;
  align-items: center;

  .filename {
    max-width: 36rem;
    font-size: 1.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 1.2rem;
  }
  .file-upload-icon {
    font-size: 2.4rem;
  }
  .file-upload-icon-complete {
    font-size: 2.4rem;
    display: none;
  }
  &.complete {
    .file-upload-icon { display: none;}
    .file-upload-icon-complete { display: inline-block; }
  }
  .file-details {
    flex: 1 1 auto;
    .filename {
      font-size: 1.6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .details {
      font-size: 1rem;
      line-height: 1;
      > * {
        margin-right: 1.2rem;
      }
    }
  }
  .file-spacer {
    flex: 1 1 auto;
  }
  .file-close {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    text-align: center;
    height: 2.4rem;
    width: 2.4rem;
    border-radius: $radius;
    background-color: rgba(255, 255, 255, 0.25);
  }

  .file-progress {
    position: absolute;
    top: 0;
    right: 50%;
    width: 110%;
    height: 100%;
    background: linear-gradient(
      to right,
      $success,
      $success 90%,
      rgba($success, 0) 100%
    );
  }
}

.file-drop-box {
  width: 100%;
  padding: 2.4rem;
  border: 1px dashed rgba($primary, 0.5);
  font-size: 1.6rem;
  text-align: center;
  display: flex;
  align-items: center;

  .fancy-or {
    flex: 1 1 auto;
    margin: 0 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    .the-actual-or {
      margin: 1.2rem;
    }
    hr {
      flex: 1 1 auto;
    }
  }
}
