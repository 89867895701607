
.discussion-message-list {
  width: 100%;
  flex: 1 1 auto;
  max-height: 24rem;
  padding-right: 0.6rem;
  overflow-y: scroll;
}

.discussion-message {
  margin-bottom: 2.4rem;
  .discussion-message-header {
    display: flex;
    justify-content: flex-start;
    .discussion-message-created-by {
      flex: 1 1 auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 1.6rem;
    }
  }
}
