
@import "@/styles/_variables.scss";

.file-box {
  display: flex;
  align-items: center;
  padding: 0.6rem;
  margin: auto -0.6rem;
  border-radius: $radius;
  &:hover {
    background-color: rgba($primary, 0.25);
  }

  .file-icon {
    font-size: 2.4rem;
  }
  .file-details {
    flex: 1 1 auto;
    .filename {
      max-width: 36rem;
      font-size: 1.6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .details {
      font-size: 1rem;
      line-height: 1;
      > * {
        margin-right: 1.2rem;
      }
    }
  }
}

.no-files {
  padding: 1.2rem;
  text-align: center;
}
